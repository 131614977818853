class Temperatures {
  async retrieveTemperatures(dateToFetch) {
    var temperatures = null;

    await fetch("https://compost-api.sherlock.uk/api/retrieve-data", {
      // Adding method type
      method: "POST",

      // Adding body or contents to send
      body: JSON.stringify({
        startDate: dateToFetch,
      }),

      // Adding headers to the request
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      // Converting to JSON
      .then((response) => response.json())
      .then((json) => {
        temperatures = json;
      });

    return temperatures;
  }
}

export default new Temperatures();
