// import "./plugins/axios";
// import { createApp } from "vue";
// import App from "./App.vue";
// import "./registerServiceWorker";
// import router from "./router";

// createApp(App).use(router).mount("#app");

import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./sherlock.css";
import "./utility.js";
import "./scripts/snow.js";

// createApp(App).use(router).use(BootstrapVue3).use(axios).mount("#app");
createApp(App).use(router).mount("#app");
