// import { getFirebaseToken } from './firebase';
// import axios from "axios";

export function urlBase64ToUint8Array(base64String) {
  var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function getToday() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  return today;
}

// export function fetchData(dateToFetch) {
// var temperatures = null;
// console.log(dateToFetch);
// axios
//   .post(`https://compost-api.sherlock.uk/api/retrieve-data`, {
//     startDate: dateToFetch,
//   })
//   .then((res) => {
//     temperatures = res.data;
//     this.setState({ temperatures });

//     const hotbin = [...temperatures];
//     const outside = [...temperatures];
//     const sortedHotbin = hotbin.sort(
//       (a, b) => parseFloat(a.hotbin) - parseFloat(b.hotbin)
//     );
//     this.setState({
//       sortedHotbinMaxTemp: sortedHotbin[sortedHotbin.length - 1].hotbin,
//     });
//     this.setState({
//       sortedHotbinMaxTime: formatDateTime(
//         sortedHotbin[sortedHotbin.length - 1].timestamp,
//         2
//       ),
//     });
//     this.setState({ sortedHotbinMinTemp: sortedHotbin[0].hotbin });
//     this.setState({
//       sortedHotbinMinTime: formatDateTime(sortedHotbin[0].timestamp, 2),
//     });

//     const sortedOutside = outside.sort(
//       (a, b) => parseFloat(a.outside) - parseFloat(b.outside)
//     );
//     // this.setState({ sortedOutside });
//     this.setState({
//       sortedOutsideMaxTemp: sortedOutside[sortedOutside.length - 1].outside,
//     });
//     this.setState({
//       sortedOutsideMaxTime: formatDateTime(
//         sortedOutside[sortedOutside.length - 1].timestamp,
//         2
//       ),
//     });
//     this.setState({ sortedOutsideMinTemp: sortedOutside[0].outside });
//     this.setState({
//       sortedOutsideMinTime: formatDateTime(sortedOutside[0].timestamp, 2),
//     });
//   });
// }

export function isDecember() {
  var today = new Date();
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!

  if (mm === 11) {
    //11 is December
    return true;
  }
  return false;
}

export function formatDateTime(dateIn, timeDate) {
  var formattedDateTime = "";
  // console.log("dateIn: " + dateIn.toString());
  var dt = null;
  if (dateIn === null) {
    dt = new Date();
  } else {
    // Format like this so Safari can tell it's a date
    dt = dateIn.toString().replaceAll("-", "/").split(".")[0];
    // console.log("dateIn: " + dateIn.toString());
    // dt = dateIn.replace("-/g", "/").split(".")[0];
  }

  dt = new Date(dt);
  var day = (dt.getDate() < 10 ? "0" : "") + dt.getDate();
  var month = (dt.getMonth() + 1 < 10 ? "0" : "") + (dt.getMonth() + 1);
  var year = dt.getFullYear();
  var hour = (dt.getHours() < 10 ? "0" : "") + dt.getHours();
  var minute = (dt.getMinutes() < 10 ? "0" : "") + dt.getMinutes();
  var seconds = (dt.getSeconds() < 10 ? "0" : "") + dt.getSeconds();

  if (timeDate === 0) {
    formattedDateTime =
      day +
      "/" +
      month +
      "/" +
      year +
      " " +
      hour +
      ":" +
      minute +
      ":" +
      seconds;
  } else if (timeDate === 1) {
    formattedDateTime = day + "/" + month + "/" + year;
  } else if (timeDate === 2) {
    formattedDateTime = year + "-" + month + "-" + day;
  } else {
    formattedDateTime = hour.valueOf() + ":" + minute.valueOf();
  }

  return formattedDateTime;
}

// export function getNotificationsEnabled() {
//     var notificationsEnabled = false;
//     if ('Notification' in window) {
//       if (Notification.permission === "granted") {
//         notificationsEnabled = true;
//         console.log('Notifications enabled');
//       } else {
//         console.log("Request permissions");
//         Notification.requestPermission(function (result) {
//           console.log("User choice: ", result);
//           if (result !== 'granted') {
//             notificationsEnabled = false;
//             console.log('Notifications NOT enabled');
//           } else {
//           notificationsEnabled = true;
//           // configurePushSub();
//           var token = getFirebaseToken();
//           console.log('Notifications enabled: Token: ' + token);
//         }
//       })
//     }}

//     // this.state = notificationsEnabled;
//     return notificationsEnabled;
//   }
