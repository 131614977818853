<template>
  <div class="home">
    <div class="row">
      <!-- <h5 style="text-align: left">Date:</h5> -->
      <p
        style="
          height: 100%;
          display: flex;
          align-items: flex-justify;
          word-spacing: 30%;
        "
      >
        <Datepicker
          id="auto"
          v-model="dateToFetch"
          :enableTimePicker="false"
          format="dd/MM/yyyy"
          @internalModelChange="this.retrieveTemperatures(dateToFetch)"
          :maxDate="new Date()"
          autoApply
        />
        <button title="Refresh screen">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            @click="this.retrieveTemperatures(this.dateToFetch)"
            width="30"
            height="30"
            viewBox="0 0 18 18"
          >
            <path
              d="M9 13.5c-2.49 0-4.5-2.01-4.5-4.5S6.51 4.5 9 4.5c1.24 0 2.36.52 3.17 1.33L10 8h5V3l-1.76 1.76C12.15 3.68 10.66 3 9 3 5.69 3 3.01 5.69 3.01 9S5.69 15 9 15c2.97 0 5.43-2.16 5.9-5h-1.52c-.46 2-2.24 3.5-4.38 3.5z"
            />
          </svg>
        </button>
      </p>
    </div>
    <div id="refresh" class="refreshedText">Updated {{ refreshDate }}</div>
    <!-- <NotificationsVue /> -->
    <MinMaxTemperatures
      :temperatures="this.temperatures"
      :key="this.temperatures"
    />
    <TemperaturesByTime
      :temperatures="this.temperatures"
      :key="this.temperatures"
    />
  </div>
  <Footer />
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/FooterVue.vue";
import MinMaxTemperatures from "../components/MinMaxTemperatures.vue";
import Temperatures from "../service/Temperatures.js";
import { formatDateTime } from "../utility.js";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref } from "vue";
// import NotificationsVue from "@/components/NotificationsVue.vue";
import TemperaturesByTime from "@/components/TemperaturesByTime.vue";

export default {
  name: "HomeView",
  components: {
    Footer,
    MinMaxTemperatures,
    Datepicker,
    // NotificationsVue,
    TemperaturesByTime,
  },

  setup() {
    const dateToFetch = ref(new Date());
    const temperatures = ref([]);
    const refreshDate = ref(new Date());
    refreshDate.value = formatDateTime(refreshDate.value, 0);

    return {
      dateToFetch,
      temperatures,
      refreshDate,
    };
  },
  methods: {
    async retrieveTemperatures(dateToFetch) {
      // console.log(
      //   "Enter retrieveTemperatures. DateToFetch: " +
      //     formatDateTime(dateToFetch, 2)
      // );
      this.temperatures = await Temperatures.retrieveTemperatures(
        formatDateTime(dateToFetch, 2)
      );
      this.refreshDate = formatDateTime(new Date(), 0);

      return this.temperatures;
    },
  },
};
</script>
