<template>
  <footer class="footer text-center">
    <img
      src="../assets/scs.png"
      alt="Sherlock logo"
      className="img-responsive center-block"
    /><br />
    <strong
      >&copy;
      <a href="https://www.sherlock.co.uk">Simon Sherlock</a> 2022</strong
    >
  </footer>
</template>

<script>
export default {
  name: "FooterVue",
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style scoped></style>
