<template>
  <h5 style="padding-top: 5px">By Time:</h5>
  <table class="table table-responsive table-striped table-bordered">
    <thead>
      <th>Time</th>
      <th>HotBin</th>
      <th>Outside</th>
    </thead>
    <tbody>
      <tr v-for="item in this.temperatures" :key="item.timestamp">
        <td>{{ formatTime(item.timestamp) }}</td>
        <td>{{ item.hotbin }}</td>
        <td>{{ item.outside }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
// import { formatDateTime } from "@/utility";
import { formatDateTime } from "../utility.js";

export default {
  name: "MinMaxTemperatures",
  props: {
    temperatures: { type: Object, required: true },
  },
  methods: {
    formatTime(timestamp) {
      return formatDateTime(timestamp, 3);
    },
  },
  // data() {
  //   var formattedMap = [];
  //   for (let i = 0; i < this.temperatures.length; i++) {
  //     formattedMap.set(
  //       "timestamp",
  //       formatDateTime(this.temperatures[i]["timestamp"]),
  //       3
  //     );
  //     // formattedMap.set("hotbin", this.temperatures[i]["hotbin"]);
  //     // formattedMap.set("outside", this.temperatures[i]["outside"]);
  //   }
  //   console.log("formattedMap: " + formattedMap);
  //   return { formattedMap };
  // },
};
</script>

<style scoped>
tbody {
  background-color: rgba(0, 0, 0, 0) !important;
}

thead {
  background-color: lightgrey;
  border-color: black;
}
td {
  border-color: black;
  border-width: 1px;
}

h5 {
  text-align: left;
}
</style>
