<template>
  <h5 style="padding-top: 5px">Min/Max Temperatures:</h5>
  <table class="table table-responsive table-striped table-bordered">
    <thead>
      <tr>
        <th></th>
        <th>Max (&deg;C)</th>
        <th>Min (&deg;C)</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>HotBin</td>
        <td>{{ sortedHotbinMaxTemp }} ({{ sortedHotbinMaxTime }})</td>
        <td>{{ sortedHotbinMinTemp }} ({{ sortedHotbinMinTime }})</td>
      </tr>
      <tr>
        <td>Outside</td>
        <td>{{ sortedOutsideMaxTemp }} ({{ sortedOutsideMaxTime }})</td>
        <td>{{ sortedOutsideMinTemp }} ({{ sortedOutsideMinTime }})</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { formatDateTime } from "../utility.js";

export default {
  name: "MinMaxTemperatures",
  props: {
    temperatures: { type: Object, required: true },
  },
  data() {
    const hotbin = [...this.temperatures];
    const outside = [...this.temperatures];
    const sortedHotbin = hotbin.sort(
      (a, b) => parseFloat(a.hotbin) - parseFloat(b.hotbin)
    );
    const sortedHotbinMaxTemp = sortedHotbin[sortedHotbin.length - 1].hotbin;
    const sortedHotbinMaxTime = formatDateTime(
      sortedHotbin[sortedHotbin.length - 1].timestamp,
      3
    );
    const sortedHotbinMinTemp = sortedHotbin[0].hotbin;
    const sortedHotbinMinTime = formatDateTime(sortedHotbin[0].timestamp, 3);
    const sortedOutside = outside.sort(
      (a, b) => parseFloat(a.outside) - parseFloat(b.outside)
    );
    const sortedOutsideMaxTemp =
      sortedOutside[sortedOutside.length - 1].outside;
    const sortedOutsideMaxTime = formatDateTime(
      sortedOutside[sortedOutside.length - 1].timestamp,
      3
    );
    const sortedOutsideMinTemp = sortedOutside[0].outside;
    const sortedOutsideMinTime = formatDateTime(sortedOutside[0].timestamp, 3);

    return {
      sortedHotbinMaxTemp,
      sortedHotbinMaxTime,
      sortedHotbinMinTemp,
      sortedHotbinMinTime,
      sortedOutsideMaxTemp,
      sortedOutsideMaxTime,
      sortedOutsideMinTemp,
      sortedOutsideMinTime,
    };
  },
};
</script>

<style scoped>
tbody {
  background-color: rgba(0, 0, 0, 0) !important;
}

thead {
  background-color: lightgrey;
  border-color: black;
}
td {
  border-color: black;
  border-width: 1px;
}

h5 {
  text-align: left;
}
</style>
